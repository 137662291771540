// Theme variables.
$--accent: --accent;
$--accent-background: --accent-background;
$--anchor: --anchor;
$--cta: --cta;
$--cta-hover: --cta-hover;
$--error: --error;
$--font-color: --font-color;
$--results: --results;

// Default theme.
$theme-map-default: (
  $--accent: #f99b1c,
  $--accent-background: #e2e0d9,
  $--anchor: #f99b1c,
  $--cta: #8dc53e,
  $--cta-hover: #f99b1c,
  $--error: #f99b1c,
  $--font-color: #54534a,
  $--results: #00a4df,
);
