@import "scss/themes.scss";
@import "scss/template.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
}

html, body {
    height: 100%;
    width: 100%;
}

body {
  height: 100%;
  width: 100%;
  font-weight: normal;
  line-height: 1.5;
  color: var($--font-color);
  -webkit-font-smoothing: antialiased;
  font-family: "Helvetica", Arial, sans-serif;
  &.fixed {
    position: fixed;
    @media(max-width: 480px){
      position: static;
    }
  }
}

.desktop {
  @media(max-width: 480px){
    display: none !important;
  }
}

.mobile {
  @media(min-width: 480px){
    display: none !important;
  }
}
