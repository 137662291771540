@import "scss/themes.scss";

[data-namespace="partnerfeed-001"] {
  ::placeholder {
    opacity: .8;
  }
  a, button {
      transition: all .25s ease;
      color: var($--anchor);
  }
  a, a:hover, a:focus, a:active {
    text-decoration: none;
  }
  .container {
    width: 100%;
    max-width: 960px;
    display: block;
    margin: 0 auto;
  }
  .button-trigger {
    display: inline-block;
    vertical-align: middle;
    padding: 0 40px 4px;
    border: 1px solid transparent;
    text-align: center;
    background-color: #8dc53e;
    background-color: linear-gradient(to bottom,  #8dc53e 0%,#87c13f 24%,#6caf44 100%);
    font-family: "Oswald", Arial, sans-serif;
    font-size: 40px;
    height: 50px;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: 40px;
    text-shadow: rgba(97, 168, 69, .7) -1px -1px 0;
    color: #fff;
    box-shadow: 0px 1px 3px rgba(1, 0, 4, .85), inset 0px 1px 0 rgba(255, 255, 255, .5);
    border-radius: 2px;
    cursor: pointer;
    transition: all .25s ease;
    &:hover, &:active, &:focus {
      background-color: rgb(251,140,34);
      background-color: linear-gradient(0deg, rgba(251,140,34,1) 0%, rgba(253,165,38,1) 100%);
    }
    @media(max-width: 480px){
      font-size: 24px;
      line-height: 24px;
      height: 34px;
    }
  }
  .button-trigger-alt {
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    border: 1px solid transparent;
    text-align: center;
    background-color: #7c7c7c;
    font-family: "Oswald", Arial, sans-serif;
    font-size: 16px;
    height: 30px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .5px;
    line-height: 27px;
    color: #ebeae4;
    border-radius: 30px;
    cursor: pointer;
    transition: all .25s ease;
    &:hover, &:active, &:focus {
      background-color: rgb(251,140,34);
    }
    &.dark {
      background-color: #000;
      &:hover, &:active, &:focus {
        background-color: rgb(251,140,34);
      }
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: "Oswald", Arial, sans-serif;
    letter-spacing: .5px;
  }
  .panel {
    background: rgba(0, 0, 0, .9);
    width: 100%;
    margin: 0 0 10px;
    border-radius: 5px;
    .panel-header {
      background-color: #8dc53e;
      background-color: linear-gradient(to bottom,  #8dc53e 0%,#87c13f 24%,#6caf44 100%);
      text-shadow: rgba(97, 168, 69, .7) -1px -1px 0;
      color: #fff;
      box-shadow:inset 0px 1px 0 rgba(255, 255, 255, .5);
      border-radius: 3px 3px 0 0;
      padding: 30px 30px 15px;
      font-family: "Oswald", Arial, sans-serif;
      font-size: 40px;
      font-weight: 700;
      &.orange {
        background-color: rgb(251,140,34);
        background-color: linear-gradient(0deg, rgba(251,140,34,1) 0%, rgba(253,165,38,1) 100%);
      }
      &.blue {
        background-color: rgb(0,156,213);
        background-color: linear-gradient(0deg, rgba(0,156,213,1) 0%, rgba(5,170,230,1) 100%);
      }
    }
    .panel-content {
      padding: 10px 30px;
      @media(max-width: 480px){
        padding: 10px;
      }
      .highlight {
        color: var($--cta-hover);
        display: inline-block;
        margin-right: 4px;
      }
      .actions-container {
        padding: 20px 30px;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          a {
            color: #fff;
            display: block;
            padding: 10px 30px;
            border-top: 1px solid #666;
            &:hover, &:focus, &:active {
              background: #2e2d2d;
              color: #f3f2ef;
            }
          }
        }
      }
    }
  }
  .isLoadingSiteData {
    opacity: 0;
  }
}
